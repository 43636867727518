@import "~apexcharts/dist/apexcharts.css";
/*@import "./plugins/formvalidation/dist/css/formValidation.css";*/
@import "~bootstrap-daterangepicker/daterangepicker.css";
@import "~select2/dist/css/select2.css";
/*@import "~nouislider/dist/nouislider.css";*/
/*@import "~dropzone/dist/dropzone.css";*/
/*@import "~quill/dist/quill.snow.css";
*/
/*@import "~@yaireo/tagify/dist/tagify.css";
*/
@import "./plugins/toastr/build/toastr.css";
/*@import "~sweetalert2/dist/sweetalert2.css";
*/
/*@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
*/
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~flatpickr/dist/flatpickr.min.css";
/*@import "~tiny-slider/dist/tiny-slider.css";
*/
/*@import "~leaflet/dist/leaflet.css";
*/